import React, { useState , useEffect } from 'react';
import { useDispatch , useSelector} from 'react-redux';
import DOMPurify from 'dompurify';

import { urlAppFile  } from '../global';
import { loadVisitsOfDayFromDB, sendSMS  } from '../customfunc';

import {startHour , endHour  } from '../global';
import { Months , Days} from './CalendarApp';


import { setVisits } from '../actions/visitsActions';
import { changeCalendar } from '../actions/kindCalendarActions';

import DialogYesAndNo from './DialogBoxComponent';
import { REMOVE_VISIT , WITH_TEXTAREA } from './DialogBoxComponent';

import "./AddNewConsumer.css";
import './VisitApp.css';

import {NEW , EDIT } from './VisitApp';

const staticMenu = true;

let pos = {x: 0 , y: 0};  
let mMove = false;

const SelectTime=(props)=>{

const handleChangeOption=(e)=> {
props.handleChangeOption( e.target.selectedOptions[0].outerText );     
}    

const optionTime=() => {
 let items = [];
items.push(<option key={Math.random*10000} >Możesz wybrać godzinę</option>)
for(let i = startHour ; i <= endHour ; i++ )
    {
        let hour = i<10 ? '0'+i : i;
        let val = hour + '00';
        let time = hour + ":00";
        items.push(<option key={i} value={val} onChange={handleChangeOption}>{time}</option> )
    }
 return items;
}

return (
<>
<select className="form-select" aria-label="Default select example"  onChange={handleChangeOption} >
{optionTime()}
</select>
</>    
) 
}

const VisitWindow = (props) => {

//Hooks
const dispatch = useDispatch();
const currentDateTime = useSelector(store => store.currentDateTime ) ;
const consumers = useSelector(store => store.consumers ) ;
const workers = useSelector(store => store.workers ) ;
//const user = useSelector(store => store.user);
const kindCalendar = useSelector(store => store.kindCalendar);
const settings = useSelector(store => store.settings); 

const [idConsumer , setIdConsumer] = useState(0);
const [dialogBoxYesAndNo , setDialogBoxYesAndNo] = useState(false);
const [busyApp , setBusyApp] = useState(false);
const [descriptionSrvs , setDescriptionSrvs] = useState('');
const [suggestedEndTimeVisit , setSuggestedEndTimeVisit] = useState('00:00');
const [endTimeOfVisit , setEndTimeOfVisit] = useState('12:00');
const [confirmSMSSend , setConfirmSMSSend] = useState({visible: false , number: '' , message: '' , question: ''}); //menu potwierdzające wysyłanie sms
const [enableCheckBoxSendBtn , setEnableCheckBoxSendBtn] = useState(true); // stan checkbox przypomnienie powiadomienia sms
const [stateBtnSendSMSNow , setStateBtnSendSMSNow] = useState(true); //stan przycisku wysyłania informaci o wizycie 
const [defaultNameConsumer , setDefaultNameConsumer] = useState({id: -1,name:'',surname:'',phone:'',fullName:''}); //pole wyszukiwarki nazwy klienta



    const handleMouseDown=(e)=>
    {
        if(!staticMenu) {
        let mainBar = document.getElementById('mainBar');
        mainBar.style.position = 'absolute';
        let xFar = e.pageX - mainBar.offsetLeft ;
        let yFar = e.pageY -mainBar.offsetTop ;
        pos = { 
            x: xFar,
            y: yFar
        }
        mMove = true;
    }
    }  

    const handleMouseUp=(e)=>
    {
        mMove = false;
    } 

    const handlerMouseMove=(ex)=>
    {
        if(mMove) {
            let mainBar = document.getElementById('mainBar');
            mainBar.style.left = `${ex.pageX-pos.x}px`; 
            mainBar.style.top = `${ex.pageY-pos.y}px`;
            }
    }
 
 window.addEventListener('mouseup' , ()=>{ 
 mMove = false;
 })   


const handleChangeOptionStart=(time)=> {
    let timeControl = document.getElementById('inputStartTime');
    timeControl.value = time;
}

const handleChangeOptionEnd=(time)=> {
    let timeControl = document.getElementById('inputEndTime');
    timeControl.value = time;
    }

const handleEndTimeChange = (event) => {
    setEndTimeOfVisit(event.target.value);
    };

const handleChangeInputSearch=(e)=> {
    const searchTexte = e.target.value;
    let foundConsumer = consumers.filter( val => {
        let sText = `${val.surname} ${val.name} - ${val.phone}`;
        if ( searchTexte.replaceAll(' ' , '' ) === sText.replaceAll(' ' , '' ) ) return true;
        else return false;
    });

    const iCon = ( foundConsumer.length === 1 ) ? foundConsumer[0] : false;
    if(iCon) {
        const iiCon = parseInt(iCon.id);
        if(iiCon > 0){
            setIdConsumer(iiCon);
            const client = {
                id: iiCon,
                name:iCon.name,
                surname:iCon.surname,
                fullName:searchTexte, //poprawić
                phone:iCon.phone
            }
            setDefaultNameConsumer(client);
            setStateBtnSendSMSNow(false);
            setEnableCheckBoxSendBtn(false);
        }
        else {
            setIdConsumer(0);
            const client = {
                id: -1,
                name:'',
                surname:'',
                fullName:'', 
                phone:''
            }
            setDefaultNameConsumer(client);
            setStateBtnSendSMSNow(true);
            setEnableCheckBoxSendBtn(true);
        }
    }  
    else {
        setIdConsumer(0);  
        const client = {
            id: -1,
            name:'',
            surname:'',
            fullName:searchTexte,
            phone:''
        }
        setDefaultNameConsumer(client);
        setStateBtnSendSMSNow(true);
        setEnableCheckBoxSendBtn(true);
    } 
}    

const showWorkers=()=> {
    let arrFilterd = workers.filter(val => parseInt(val.visible));    

    let arr = arrFilterd.map( val => (
    <option key={val.id} value={val.id}>{val.namesurname}</option>    
    ))    
    return arr;    
}

const showFiltredConsumers=()=> {
    let filtredConsumers = consumers.map(val => (
    <option key={val.id} >{val.surname} {val.name} - {val.phone}</option>    
    ));
    return filtredConsumers;
}

//saveing or editing button to visit actions
let handleButton1 = (kindAction) => {
    let nameConsumer = ""; 
    if(parseInt(idConsumer) === 0 )  {
        alert('Uwaga: Nie znaleziono klienta');    
        //return false;
        nameConsumer = document.getElementById( 'inputClient' ).value;         
        }

    const smsNotification = document.getElementById('flexCheckCheckedSms').checked ? 1 : 0;
    const inpWork = document.getElementById('workerSelect');   
    const idWorker = parseInt(inpWork.value);
    if(idWorker  === -1 )  {
        alert('nie wybrano pracownika');    
        return false;
        }

    const idVisit = document.getElementById('idVisit');
    const startTime = document.getElementById('inputStartTime');
    const[startHour , startMinutes] = startTime.value.split(':');
    const sD1 = currentDateTime.getFullYear()+'-'+(currentDateTime.getMonth()+1)+'-'+currentDateTime.getDate()+' '+startHour+':'+startMinutes+':00';

    const endTime = document.getElementById('inputEndTime');
    const[endHour , endMinutes] = endTime.value.split(':');
    const eT1 = currentDateTime.getFullYear()+'-'+(currentDateTime.getMonth()+1)+'-'+currentDateTime.getDate()+' '+endHour+':'+endMinutes+':00';

    const tempStartTime = new Date(currentDateTime.getFullYear() ,(currentDateTime.getMonth()+1) , currentDateTime.getDate() , startHour , startMinutes , 0 );
    const endStartTime = new Date(currentDateTime.getFullYear() ,(currentDateTime.getMonth()+1) , currentDateTime.getDate() , endHour , endMinutes , 0 );

    if(tempStartTime >= endStartTime) {
        alert('BŁĄD: Godzina rozpoczęcia wizyty jest późniejsza niż zakończenia');
        return false;
       }

    const visitDescription = document.getElementById('visitDescription');
    const price = document.getElementById('price');
    const payment = document.querySelector('[name=flexRadioDefault]:checked');
    const paymentMethod = parseInt(payment.id);
    //save visit    
    let _act = null;
    if(kindAction === "NEW") _act = "saveVisit";
    else if(kindAction === "EDIT") _act = "editVisit";
    let objCon = { 
        Action: _act ,
        idVisit: idVisit.value ,
        startDate: sD1 ,
        endDate: eT1 ,
        idConsumer: idConsumer, 
        nameConsumer: nameConsumer ,
        idWorker: idWorker , 
        alt: visitDescription.value ,
        smsNotification,
        price: price.value , 
        payMethod: paymentMethod 
    };
    
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    setBusyApp(true);
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
             
        if(json.status === "success") {
            dispatch( changeCalendar({kind: 2 , calData: null}) ); 
            loadVisitsOfDayFromDB( currentDateTime ).then(resolve => {
                dispatch( setVisits(resolve) );      
                setBusyApp(false);    
                });
            }
        else {
            alert('BŁĄD: Nie udało się dodać/edytować wizyty');
            }   
        })
        .catch((e)=>alert(e));
};

const handleButton3 = () => { // delete handle button
    setDialogBoxYesAndNo(true);    
    window.scrollTo({
        top: 0,
        left: 0 , 
        behavior: 'smooth'
    }) 
    };

const handleButtonYes=()=> {
    let objCon = {
        Action: "deleteVisit",
        idVisit
        };      
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  

    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(data => {
            if(data.status === "success") {
                loadVisitsOfDayFromDB( currentDateTime ).then(resolve => dispatch( setVisits(resolve) ) );      
                }
            else {
                alert('Nie można usunąć wizyty');  
                }
        })
        .catch((e)=>alert(e));    
    dispatch( changeCalendar({kind: 2 , calData: null}) ); 
    setDialogBoxYesAndNo(false);   
}    

const handleButtonNo=()=> {
    setDialogBoxYesAndNo(false);   
} 

let handleButton2 = () => { // close handle button
dispatch( changeCalendar({kind: 2 , calData: null}) ); 
};


const handleAddServices=(name )=> {
    let descriptionText = '';
    descriptionText = descriptionSrvs ? `${descriptionSrvs}, ${name}` : name;
    setDescriptionSrvs(descriptionText);
    setSummaryTimeVisits(descriptionText); 
}


const handeChangeDescriptionText = (e)=> {
    setDescriptionSrvs(e.target.value);
    setSummaryTimeVisits();
}

const getServicesButtons=()=>{
    const btnServices = JSON.parse(settings.buttons_services);
    const Arr = btnServices.buttons.map( (val , index)=> {
        return(<button key={index} className='btn btn-outline-dark btn-xsm mr-2 mb-1' onClick={()=>handleAddServices( val.name , val.time )}>{val.name}</button>);
    })
    return Arr;
}

//liczenie sugerowanego zakończenia wizyty
const setSummaryTimeVisits =( descText = '' )=>{
    const descriptionText = descText !== '' ? descText : descriptionSrvs;
    const btnServices = JSON.parse(settings.buttons_services);
    let time = 0;
    btnServices.buttons.forEach(element => {
        const s = descriptionText.indexOf(element.name);
        if(s > -1) {
            const tElm = parseInt(element.time);
            if(tElm !== 'NaN') {
                time += tElm;
            }
         };
    });
    time = time * 60 * 1000; //miliseconds
    const sTV = new Date(kindCalendar.calData.startDate);
    const suggestedTD = new Date(sTV.getTime() + time);
    const suggTime = ((suggestedTD.getHours()<10) ? '0' : '') + suggestedTD.getHours() + ':' + ( suggestedTD.getMinutes() < 10 ? '0' : '') + suggestedTD.getMinutes();
    setSuggestedEndTimeVisit( suggTime );
}

const handleSuggestedBtnClick = ()=> {
    setEndTimeOfVisit(suggestedEndTimeVisit);
}


//TODO --- TRZEBA się zabezpieczyć aby nie kliknąć wyślij bez upewnienia się o zapisaniu wizyty
const handleOpenSendSMSBox = ()=> {
    const client = defaultNameConsumer;
    const dtS = new Date(kindCalendar.calData.startDate); //Do poprawy bo godzina nie jest zapisana
    const dtSM = (dtS.getDate() < 10 ? '0' : '' ) + dtS.getDate() + '.' + ((dtS.getMonth()+1) < 10 ? '0' : '' ) + (dtS.getMonth()+1) + '.' + dtS.getFullYear();
    const tmSM = ((dtS.getHours()<10) ? '0' : '') + dtS.getHours() + ':' + ( dtS.getMinutes() < 10 ? '0' : '') + dtS.getMinutes();
    let message = settings.text_notification2;
    message = message.replace("{time}", tmSM).replace("{date}", dtSM);
    const question = `Czy chcesz wysłać wiadomość do <b>${client.name} ${client.surname} - ${client.phone}</b> o treści: `;
    const cnfS = {
        visible: true,
        number: client.phone,
        question,
        message
    }
    setConfirmSMSSend(cnfS);
}

const handelSendMessage=()=> {
    const cnfS = {
        visible: false,
        number: '',
        question: '',
        message: ''
    }
    sendSMS(confirmSMSSend.message , confirmSMSSend.number ).then(resp=>{
        if(resp.status) {
            switch(resp.status) {
                case 'success':{
                    console.log(resp.message); //Obsługa wysłania sms
                    //dopisać jakieś info że sms został wysłany
                    break;
                }
                case 'error': {
                    console.warn(resp.message); //Obsługa wysłania sms
                    break;
                }
                default: {
                    console.warn('case is not supported'); //Obsługa wysłania sms
                    break;
                }
            }
        }
        else console.log('error sending sms '); //błędy wysyłania sms
    })
    .catch(err=>{
        console.warn(err); //błędy wysyłania sms
    });
    setConfirmSMSSend(cnfS);
}

const handelCloseMessageDialog=()=>{
    const cnfS = {
        visible: false,
        number: '',
        question: '',
        message: ''
    }
    setConfirmSMSSend(cnfS);
}

//useEffects
useEffect( ()=> {
    if(kindCalendar.kind===EDIT) { 
        setIdConsumer(kindCalendar.calData.consumer );    //only edit
        setDescriptionSrvs(kindCalendar.calData.alt);
        setSummaryTimeVisits(kindCalendar.calData.alt);

        let eT = new Date(kindCalendar.calData.endDate);
        const mins = eT.getMinutes() < 10 ? `0${eT.getMinutes()}` : eT.getMinutes();
        const edt = eT.getHours() < 10 ? `0${eT.getHours()}:`+mins : `${eT.getHours()}:` + mins;
        setEndTimeOfVisit(edt);

        const client ={
            id:defaultConsumer.id,
            name:defaultConsumer.name,
            surname:defaultConsumer.surname,
            fullName:defaultConsumer.fullName,
            phone:defaultConsumer.phone
        }
        setDefaultNameConsumer(client);
        setEnableCheckBoxSendBtn(false);
        setStateBtnSendSMSNow(false);

        }

    if( kindCalendar.kind === NEW ) {
        const hour = kindCalendar.calData.startDate.getHours();
        const edt = hour < 10 ? `0${hour+1}:00` : `${hour+1}:00`;  
        setEndTimeOfVisit(edt);

        setDescriptionSrvs('');
        //ustawienie sugerowanej godziny na początkową
        const stD = kindCalendar.calData.startDate;
        const suggTime = ((stD.getHours()<10) ? '0' : '') + stD.getHours() + ':' + ( stD.getMinutes() < 10 ? '0' : '') + stD.getMinutes();
        setSuggestedEndTimeVisit(suggTime );
    }
    window.scrollTo({
        top: 0,
        left: 0 , 
        behavior: 'smooth'
    })    
  } , [kindCalendar] );

let nameButton1 = "Zapisz";
let nameButton3 = "Usuń";
let calendarCurrentDateTime =  Days[currentDateTime.getDay()] + ", " +currentDateTime.getDate() + " " + Months[ currentDateTime.getMonth() ]  + " "  + currentDateTime.getFullYear();

let idVisit = null;
let smsNotification = true;
let startTime = "11:00";
let endTime = endTimeOfVisit;
let defaultWorker = null;
let defaultConsumer = {
    id:-1,
    name:'',
    surname:'',
    fullName:'',
    phone:''
};
let defaultConsumers = showFiltredConsumers() ;
let kindAction = null;
let defaultDescription = descriptionSrvs;
let defaultPrice = null;
let cashMethod = true;
let cardMethod = false;
const buttons = getServicesButtons();

switch( kindCalendar.kind ) {
    case NEW: {   //00110 - new
        const hour = kindCalendar.calData.startDate.getHours();
        startTime =  hour < 10 ? `0${hour}:00` : `${hour}:00`; 
        //endTime = hour < 10 ? `0${hour+1}:00` : `${hour+1}:00`;  
        endTime = endTimeOfVisit;
        defaultWorker = kindCalendar.calData.worker.id;
        //defaultConsumer = "";
        kindAction = "NEW";
        
    break;    
  }  
  case EDIT: {    //01010 - Edit
        kindAction = "EDIT";
        idVisit = parseInt(kindCalendar.calData.id);
        smsNotification = parseInt(kindCalendar.calData.smsNotification);
        let sT = new Date(kindCalendar.calData.startDate);
        let currentGMT = 0;
        let min = sT.getMinutes() < 10 ? `0${sT.getMinutes()}` : sT.getMinutes();
        startTime = (sT.getHours()+currentGMT) < 10 ? `0${(sT.getHours()+currentGMT)}:`+min : `${(sT.getHours()+currentGMT)}:`+min;

        //let eT = new Date(kindCalendar.calData.endDate);
        //min = eT.getMinutes() < 10 ? `0${eT.getMinutes()}` : eT.getMinutes();
        //endTime = (eT.getHours()+currentGMT) < 10 ? `0${(eT.getHours()+currentGMT)}:`+min : `${(eT.getHours()+currentGMT)}:`+min;
        endTime = endTimeOfVisit;

        defaultWorker = parseInt(kindCalendar.calData.worker);

        if( parseInt(kindCalendar.calData.consumer) === 0) {
            //defaultConsumer = kindCalendar.calData.nameConsumer ;
        }
        else if( parseInt(kindCalendar.calData.consumer) > 0  ) {
            let arrFiltred = consumers.filter(val => {
                if( parseInt(val.id) === parseInt( kindCalendar.calData.consumer ) )  return true;
                return false;
            }); 
            let defCon = {
                id: -1, 
                name : 'imię',
                surname: 'nazwisko',
                phone: '+48'    
                }
            arrFiltred = (arrFiltred.length === 1 ) ? arrFiltred[0] : defCon;
            defaultConsumer.fullName = arrFiltred.surname + ' ' + arrFiltred.name + ' - ' + arrFiltred.phone;
            defaultConsumer.id = arrFiltred.id;
            defaultConsumer.name = arrFiltred.name;
            defaultConsumer.surname =  arrFiltred.surname;
            defaultConsumer.phone = arrFiltred.phone;
            // btnSMSSendDisabled = false;
            // btnSMSSendAutoDisabled = false;
        }
        
        //defaultDescription = kindCalendar.calData.alt;

        defaultPrice = kindCalendar.calData.price;
        if ( parseInt( kindCalendar.calData.payMethod ) === 1 ) { 
                cashMethod = true;
                cardMethod = false;
                }
        if (parseInt( kindCalendar.calData.payMethod ) === 2 ) { 
                cashMethod = false;
                cardMethod = true;
                 }  
        
        nameButton1 = "Zapisz";         

    break;    
    }
  default: {
    break;
  }    
}

    return ( 
     <>
     <div className='bgOpenMenu'></div>
    <div className="openMenu">
        <div>
            <div className="row-1">
                
                
                    <div id="mainBar" className="mainBarVisit" onMouseMove={handlerMouseMove}>
                        <div id="topBar" className="topBar" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                            <p style={{textAlign: "left"}}>Nowa Wizyta {idVisit && ` - ${idVisit}` }</p>
                        </div>
                        <div className="centralBar">
                            <input type="text" className="strong invisible" id="idVisit" defaultValue={idVisit} />
                            <div className="input-group mb-3" style={{justifyContent: 'center'}}>
                            <span className="input-group-text" id="basic-addon1"> Data wizyty: </span>    
                            <div className="DateTime" style={{backgroundColor: "white" , border: "1px solid #808080" , borderRadius: "5px" , float: "left" , margin: "3px 0px 0px 20px"}}>
                            <div id="DateTime" style={{margin: "3px 6px 3px 6px"}}>{DOMPurify.sanitize(calendarCurrentDateTime)}</div>
                            </div>
                            </div>

                            <div style={{width: '120px'}}></div>
                            <div className="input-group mb-3" style={{justifyContent: 'center' }}>
                                <span className="input-group-text" id="basic-addon1" style={{ width: '40%'}}> Czas rozpoczęcia wizyty: </span>                          
                                <input 
                                    id='inputStartTime' 
                                    type='time' 
                                    defaultValue={DOMPurify.sanitize(startTime)} 
                                />
                                <SelectTime handleChangeOption={handleChangeOptionStart} />
                            </div>

                            <div id='endTime' className="input-group mb-3"  style={{justifyContent: 'center'}}>
                          
                                <span className="input-group-text" id="basic-addon1" style={{ width: '40%'}}> Czas zakończenia wizyty: </span>
                                <input
                                    id="inputEndTime"
                                    type="time"
                                    /*value={endTimeOfVisit}*/
                                    value={DOMPurify.sanitize(endTime)}
                                    onChange={handleEndTimeChange}
                                />
                                <SelectTime handleChangeOption={handleChangeOptionEnd}/> 
                            </div>                
                            <label htmlFor="exampleDataList" className="form-label" style={{marginBottom: '1rem!important'}}>Wyszukiwanie klienta</label>
                            <input  className="form-control" 
                                    list="datalistOptions" 
                                    id="inputClient" 
                                    placeholder="Wpisz aby wyszukać" 
                                    value={DOMPurify.sanitize(defaultNameConsumer.fullName)}
                                    onChange={handleChangeInputSearch}
                                    />
                            <datalist id="datalistOptions" >
                            {defaultConsumers && showFiltredConsumers()}    
                            </datalist>
                            <div className='row mt-2'>
                                <div className='col-md-2'>
                                    <span id='idConsumer' style={{marginBottom: '1rem!important' , marginTop: '1rem!important'}}>Id klienta: {idConsumer && idConsumer}</span>
                                </div>
                                <div className='col-md-3'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedSms" disabled={enableCheckBoxSendBtn} defaultChecked={smsNotification} alt='Wyśle jeden dzień przed wizytą wiadomość SMS z przypomnieniem'/>
                                        <label className="form-check-label" htmlFor="flexCheckCheckedSms">
                                            Przypomij SMS-em o wizycie
                                        </label>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <div className="form-check">
                                        <button className='btn btn-outline-dark btn-xsm mr-2 mb-1' disabled={stateBtnSendSMSNow} onClick={handleOpenSendSMSBox}>Wyślij SMS o wizycie teraz</button>
                                    </div>
                                </div>    
                            </div>

                            <div className="input-group mb-3 mt-3">
                            <span className="input-group-text" id="basic-addon1">Pracownik: </span>
                            <select id="workerSelect" className="form-select" aria-label="Default select example" defaultValue={DOMPurify.sanitize(defaultWorker)}>
                            <option value="-1" >Wybierz pracownika</option>
                            {workers && showWorkers()}
                            </select>
                            </div>

                            <div className='general mt-4'>
                                <label className='labelVisits' >Opis Usługi</label>
                                <div className="input-group mb-1" style={{marginBottom: '1rem!important'}} >
                                <span className="input-group-text">Opis</span>
                                <textarea id='visitDescription' className="form-control" aria-label="With textarea" value={DOMPurify.sanitize(defaultDescription)} onChange={handeChangeDescriptionText} ></textarea>
                                </div>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="inputGroup-sizing-default">szybkie dodawanie:</span>
                                                <div className="form-control buttonsBox " aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" >
                                                    <div className='btnsSrv'>
                                                        {buttons}
                                                    </div>
                                                </div>
                                            </div>    
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="input-group mb-3">
                                                    <span className="input-group-text suggestedBtn" id="inputGroup-sizing-default" onClick={handleSuggestedBtnClick}>sugerowane zakończenie:</span>
                                                    <span className="form-control buttonsBox " aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" >
                                                    {suggestedEndTimeVisit}
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="input-group mb-3" style={{marginTop: '1rem!important'}}>
                                <span className="input-group-text" id="basic-addon1">Cena: </span>
                                <input  id='price' className="form-control" defaultValue={defaultPrice} />

                                <div id='radioInp' className="form-check" style={{marginLeft: '1rem' , }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="1" defaultChecked={cashMethod}/>
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Gotówka
                                    </label>
                                </div>
                                <div className="form-check" style={{marginLeft: '1rem' , }}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="2" defaultChecked={cardMethod} />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Terminal
                                    </label>
                                </div> 
                            </div>
                            <div style={{clear:"both"}}></div>
                        </div>
                        <div className="bottomBar">
                            <div className="buttons_main">
                            <button type="button" className="btn btn-dark btn-sm button-make" disabled={ busyApp && 'disabled' }onClick={()=>handleButton1(kindAction)}>{nameButton1}</button>   
                            { kindCalendar.kind === 18 && <button type="button" className="btn btn-dark btn-sm button-make" onClick={handleButton3}>{nameButton3}</button>   }              
                            <button type="button" className="btn btn-dark btn-sm button-close" onClick={handleButton2}>Zamknij</button>
                            </div></div>
                    </div>
            </div>
        </div>
        </div>
        { dialogBoxYesAndNo &&  <DialogYesAndNo 
        titleBar={'Potwierdzenie'} 
        type={REMOVE_VISIT} 
        question={'Czy jestes pewien usunąc wizytę?'} 
        handleButtonYes={handleButtonYes} 
        handleButtonNo={handleButtonNo} 
        idVisit={idVisit} /> }

        { confirmSMSSend.visible &&  <DialogYesAndNo 
        titleBar={'Wysyłanie wiadomości SMS'} 
        type={REMOVE_VISIT} 
        typeDialogBox={WITH_TEXTAREA}
        textareaContent={confirmSMSSend.message}
        question={confirmSMSSend.question} 
        handleButtonYes={handelSendMessage} 
        handleButtonNo={handelCloseMessageDialog} 
        idVisit={idVisit} /> }
     </>   
     );
}
 
export default VisitWindow;