import { urlAppFile , urlBackupLocal , polishCharacters } from '../src/global';

//needed to frozen app in async communication from API
//użuwane aby zapobiedz wysyłaniu/odbieraniu danych w jednym momencie.
//19.03.2024 - obsługa zmiany daty kalendarza powinna zablokować akcje dynamicznej aktualizacji
//appNetStatus - jest aktywowane w momencie zmiany kalendarza
export const FREE = 0;
export const BUSY = 1;
let statusNetApp = FREE;
export const appNetStatus=(status = -1)=>{
if( status === -1 ) return statusNetApp;
if(status > -1) {
  statusNetApp = status;
  return status;
  }
}
//------------------------------------------------------------
/*
export const CHANGING = 1;
let updatingApp = FREE;
export const changeOfDay=(status = -1)=>{
if( status === -1 ) return updatingApp;
if(status > -1) {
  updatingApp = status;
  return updatingApp;
  }
}
*/
//-----------------------------------------------------------

let interval1 = 0;
export function setIdInterval(id) {interval1 = id};
export function getIdInterval() {return interval1};

//function to check
export const isSession=()=> {
  return new Promise((resolve , reject) => {       
      const objCon = { 
        Action: "isSession"
      };
      var urlData = new URLSearchParams();
      urlData.append('var1', JSON.stringify(objCon) );  
      fetch(urlAppFile , {
          method: 'POST' , 
          credentials: 'include' , 
          body: urlData  
          })
          .then(response => {
            if(response != null) return response.json();
          })
          .catch(err => {
              let errObj = {
              status: 'error',  
              error: err ,
              date : new Date().toString()
            }
            reject( errObj );
          })
          .then(json => {
            resolve( json ); 
          })
          .catch(err => {
              let errObj = {
              status: 'error',  
              error: err ,
              date : new Date().toString()
            }
            reject( errObj );
          })  
  });
  }

  export const logging=(login , password)=> {
    return new Promise((resolve ) => {       
        var objCon = { 
          Action: "login",
          login,
          password
        };
        var urlData = new URLSearchParams();
        urlData.append('var1', JSON.stringify(objCon) );  
        fetch(urlAppFile , {
          method: 'POST',
          body: urlData
        } )
        .then(response => response.json())
        .then(json => { resolve( json ) }
        )
        .catch((e)=>alert("błąd bazy danych: " + e))  
    });
    }

    export const logoutUser=()=> {
      return new Promise((resolve) => {       
          var objCon = { 
            Action: "logout"
          };
          var urlData = new URLSearchParams();
          urlData.append('var1', JSON.stringify(objCon) );  
          fetch(urlAppFile , {
              method: 'POST' , 
              body: urlData  
              })
              .then(response => {
                if(response != null) return response.json();
              })
              .then(json => {
              resolve( json ); 
              })
              .catch((e)=>{
                console.log('Błąd aplikacji: ' + e);
              });  
      });
      }   
      
let counterErrorLVD = 0;

export const loadVisitsOfDayFromDB=(date = null)=> {
  return new Promise((resolve , reject) => {
  let _date = date===null ? new Date() : date;
  let sD = new Date( _date.getFullYear() , _date.getMonth() , _date.getDate() );
  let eD = new Date( _date.getFullYear() , _date.getMonth() , _date.getDate()+1 );
    
    var objCon = { 
      Action: "LoadVisitsOfDay" ,
      startDate: sD ,
      endDate: eD 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );
      fetch(urlAppFile , {
          method: 'POST' ,  
          body: urlData  
          })
          .then(response => {
            if(response.ok) return response.json() 
            else throw new Error('zgłoszono wyjątek odpowiedzi fetch!!!');
          })
          .catch(err => {
              let errObj = {
              error: err ,
              counter: counterErrorLVD , 
              date : new Date().toString()
            }
            
            reject( errObj );
          })
          .then(json => {
            counterErrorLVD = 0;
            resolve(json) ;
          })
          .catch(errJSON=> {
            console.log("error parser: " + errJSON);
          })   
      })
      .catch(e=> {
        //obsługa braku sieci
        if(typeof e === 'object' && (!Array.isArray(e)) && e !== null  ) {
          if(e.error) {
            let errObj = e;
            errObj.counter = counterErrorLVD;
            errObj.date = new Date().toString();
            return errObj;
          }
          else return( e);
          }
        });
}

//function to load workers ro database
export const loadWorkersFromDB=()=> {
return new Promise((resolve , reject) => {       
    const objCon = { 
      Action: "loadWorker"
    };
    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
        resolve( json ); 
        }
        )
        .catch(()=>alert('błąd bazy danych'))  
});
}

//load consumers from DB and write to global store by redux
export const loadConsumersFromDB=()=> {  
  return new Promise((resolve , reject)=> {
  const objCon = { Action: "loadConsumers" };
  let urlData = new URLSearchParams();
  urlData.append('var1', JSON.stringify(objCon) );  
  fetch(urlAppFile , {
      method: 'POST' ,  
      body: urlData  
      })
      .then(response => response.json())
      .then(json => {               
      resolve( json );      
      }
      )
      .catch(()=>alert('błąd bazy danych'))  
  } )
}

export const loadSettingsFromDb=()=> {
  return new Promise((resolve, reject) => {
    const objCon = { 
      Action: "loadSettings" 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
          //dispatch(loadSettings(json[0]));
          resolve(json);
        }
        ) 
  })

}

export function loadVisitOfConsumer( id ) {
  return new Promise((resolve , reject) => {  
    var objCon = { 
      Action: "loadVisitOfConsumer" ,
      id
    };
  
    var urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
          resolve( json );
        }
        )
        .catch( error => {
          const errJSON = {
            status: 'error',
            error: error
          }
          reject(errJSON);
        }) 
      })
}


export const getPointsSMSApi=()=> {
  return new Promise((resolve, reject) => {
    const objCon = { 
      Action: "getPointsSMSApi" 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
          //dispatch(loadSettings(json[0]));
          resolve(json);
        }
        )
        .catch(()=>alert('błąd smsapi.pl'))   
  })

}

export const sendSMS=( smsMessage='' , phone='' )=> {
  return new Promise((resolve , reject) => {  
    //---------------------------
    // const debug = false;    
    //const POLISHCHARACTERS = false;
    //---------------------------

    if(smsMessage==='' || smsMessage===null) throw new Error('wrong data input');

    if(!polishCharacters) {
        smsMessage = smsMessage.replaceAll( 'ą' , 'a');
        smsMessage = smsMessage.replaceAll( 'ę' , 'e');
        smsMessage = smsMessage.replaceAll( 'ś' , 's');
        smsMessage = smsMessage.replaceAll( 'ł' , 'l');
        smsMessage = smsMessage.replaceAll( 'ć' , 'c');
        smsMessage = smsMessage.replaceAll( 'ż' , 'z');
        smsMessage = smsMessage.replaceAll( 'ź' , 'z');
        smsMessage = smsMessage.replaceAll( 'ó' , 'o');
    }

    const objCon = {
        Action: "sendSMS",
        phone: phone ,
        message: smsMessage
        };         
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );

    // if(debug) {
    //   reject({
    //     status: 'error',
    //     message: 'debug mode'
    //   });
    // }

    fetch(urlAppFile , {
      method: 'POST' ,  
      body: urlData  
      })
      .then(response => response.json())
      .then(data => {
        // if(Array.isArray(data.list) && data.list) {
        //   if (data.list[0].status && data.list[0].status === 'QUEUE') {
        //     const resp = {
        //     status:'success',
        //     message: data.list[0].status
        //     }
        //     resolve(resp);
        //   }  
        //   else {
        //     throw new Error('issue with sending sms message');
        //   }
        // }
        if(typeof data === 'object') {
          if(data.status) {
            switch(data.status) {
              case 'success': {
                if(data.data){
                  if (data.data.list[0].status && data.data.list[0].status === 'QUEUE') {
                    const resp = {
                    status:'success',
                    message: data.data.list[0].status
                  }
                  resolve(resp);
                  }  
                  else {
                    throw new Error('no sms send');
                  }
                }
                else {
                  throw new Error('invalid data format');
                }                
                break;
              }

              case 'error': {
                if(data.message) {
                  throw new Error(data.message);
                  
                }
                else throw new Error('undefined error');
                break;
              }

              default: {
                throw new Error('undefined error');
              }
            }
              
            if(data.status === 'error') {
              throw new Error(data.message);
            }
            if(data.status === 'error') {
              throw new Error(data.message);
            }
            else {
              throw new Error('Unidentified error');
            }
          }
          else {
            throw new Error('Unidentified error');
          }
        }
        else {
          throw new Error('invalid data format');
        }  
      })
      .catch(err => {
        reject({
          status: 'error',
          message: err
        });
      }); 
  })
}


export const getDeviceInfo=()=> {
  return new Promise((resolve, reject) => {
    const objCon = { 
      Action: "getDeviceInfo" 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
          resolve(json);
        }
        )
        .catch(()=>alert('błąd pobieranie info o urządzeniu. Spoóbuj odświerzyć aplikację'));   
  })
}

export const makeBackup=()=>{
  return new Promise((resolve, reject) => {
    const objCon = { 
      Action: "makeBackup" 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    fetch(urlAppFile , {
        method: 'POST' ,  
        body: urlData  
        })
        .then(response => response.json())
        .then(json => {
          resolve(json);
        }
        )
        .catch(()=>console.log('błąd połączenia'));   
  })

}

export const createLocalBackup=(fileName)=>{
  return new Promise((resolve , reject) => {
    const objCon = { 
      Action: 'createLocalBackup',
      fileName 
    };
  
    let urlData = new URLSearchParams();
    urlData.append('var1', JSON.stringify(objCon) );  
    
    fetch( urlBackupLocal , {
        method: 'POST' ,  
        body: urlData  
        })
        // .then((response) => {
        //   if(response.ok) return response.json();
        //   else reject('bad server request');  
        // })
        // .then(json => {
        //   resolve(json);
        // }
        // )
        // .catch((e) => resolve('{"status":"error" , "message":"' + e + '"}'));   
  })

}