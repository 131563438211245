import React , { useEffect } from 'react';
import { useDispatch , useSelector } from 'react-redux';
import VisitWindow from './VisitWindow';
import { changeCalendar } from '../actions/kindCalendarActions';
import { setWorkers } from '../actions/workersActions';

import { endHour , heightRowCalendar, webAddress } from '../global';
import { getIdInterval, isSession } from '../customfunc';

import './VisitApp.css';

export const NEW = 10;
export const EDIT = 18;

const VisitBox=(props)=> {
const dispatch = useDispatch();    

const consumers = useSelector(store => store.consumers);  

//const settings = useSelector(store => store.settings);  

//editing visits
const handleEditVisit=(visit)=> {
dispatch(changeCalendar({kind: EDIT , calData: visit } )); //edit
}
 
useEffect( ()=> {

}  );
    

const box01=()=> { //wyświetlanie pudełka wizyty
    let vvvis = props.visits.map(val => {
        //console.log("test 123");
        let sV = new Date(val.startDate);
        /*Wyłączono kod 04.12.2024 - zmiany dotyczące dat UTC
        let addHour = settings.time_zone;
        addHour = parseInt(addHour);
        if(typeof(addHour ) === 'number') {
            sV.setHours(sV.getHours() + addHour );
            }           
            */

        if (sV.getHours() === props.hour) {

            let eV = new Date(val.endDate);
            //Wyłączono kod 04.12.2024 - zmiany dotyczące dat UTC
            //if(typeof(addHour ) === 'number') {
            //    eV.setHours(eV.getHours() + addHour );
            //    }  
            let hD = eV.getTime() - sV.getTime();
            let heightBoxVisit = heightRowCalendar * hD / 3600000;
            let hourStart = sV.getMinutes();
            let marginTopBoxVisit = heightRowCalendar * hourStart / 60;
            let defCon = {
                id: -1, 
                name : 'imię',
                surname: 'nazwisko',
                phone: '+48'    
                }
            let noneDefCon = {
                id: null, 
                name : '',
                surname: val.nameConsumer,
                phone: ''    
                }    
            //Wizualizacja danych powinna być ustawiana w settingu
            //tymczasowe na potrzeby salonu    
            let payText = '';
            if(val.payMethod === 1) payText = 'G';
            if(val.payMethod === 2) payText = 'T';
            //tymczasowe do ustawienia w settings
            let currency = 'zł';
                
            let consumer = consumers.filter( con => parseInt(con.id) === parseInt(val.consumer))
            consumer = ( consumer.length === 1 ) ? consumer[0] : defCon;
            consumer =  (parseInt(val.consumer) === 0 )  ? noneDefCon : consumer ;             

            let nameSurmane = `${consumer.surname} ${consumer.name}`;
            return (
                <div key={val.id} className='visitBox' >
                    <div className='visitBoxMain' style={{}}>    
                        <div className='visitBoxFuture' style={{
                            backgroundColor: `${props.work.color}DC`,
                            marginTop: `${marginTopBoxVisit}px`,
                            height: `${heightBoxVisit}px`,
                            overflow: 'auto'
                        }}
                        onClick = {()=>handleEditVisit(val)} >
                            <div className='firstLineOfVisitBox' >{`${sV.getHours()}:${sV.getMinutes() === 0 ? "00" : sV.getMinutes()} - ${eV.getHours()}:${eV.getMinutes() === 0 ? "00" : eV.getMinutes()}`}</div>
                            <div className='secondLineOfVisitBox' >{nameSurmane}</div>
                            {val.alt && <div className='thirdLineOfVisitBox' >{val.alt}</div>}
                            {val.price && <div className='thirdLineOfVisitBox' >{val.price}{currency}-{payText}</div>}
                        </div>
                    </div>
                </div>
            );
        }
        else return null;
    }
        
    );
    return vvvis;
}


return (
<>
<div key={Math.random() * 999999} className='mainBoxVisits'>
{box01(props.visits) }
{ /* ((kindCalendar & 18)===18)  && <VisitWindow /> if kindCalendar is 18 than true*/  } 
</div>

</>
)    
}

const WorkerApp=(props)=> {
const dispatch = useDispatch();
const visits = useSelector(store => store.visits );
const workers = useSelector(store => store.workers );
const settings = useSelector(store => store.settings );
const currentDateTime = useSelector(store => store.currentDateTime );

let _visits = null;
const startHour = settings.start_calendar_hour;

if(visits){
    if(typeof visits === 'object' && (!Array.isArray(visits)) && visits !== null  ) {
        if(visits.session) {
            if(visits.session.status) {
                clearInterval(getIdInterval());
                visits.session.status === 'notLogged' && (document.location.href=webAddress);    
            }
        }
    }
    if(Array.isArray(visits)) {
        _visits = visits==null ? null : visits.filter( val =>  parseInt(val.worker) === parseInt(props.work.id) );
    } 
}

const isSess=()=> {
    return new Promise( (res ) => {
        isSession().then( resolve => {
            if(typeof resolve === 'object' && (!Array.isArray(resolve)) && (resolve !== null)  ) {
                if(resolve.session.status) {
                    if(resolve.session.status === 'notLogged') res(false);                
                    if(resolve.session.status === 'logged') res(true);            
                }
                else res (false);
            } else res(false);
        })
        .catch((e)=>{
            console.log('Błąd aplikacji: ' + e);
            alert('Błąd aplikacji: ' + e);
            document.location.href = webAddress;
          });

    });
 
}
 
const handleAddVisit=(hour , worker , startDate )=> {

startDate.setHours(hour , 0 , 0 , 0 );  
let obj = {
    startDate, 
    worker    
}
isSess().then(resp => {
    if(resp) {
        dispatch( changeCalendar({kind: 10 , calData: obj}) ); 
    }
    else document.location.href = webAddress;
}) ;


}

const handleShowCalendarWorker=(work , id)=> {
let _workers = workers.map( (val , index  ) => {
    if( val.active && id !== index ) {
        val.visible = "0";
        return val;
        }
    else return val;  
});
dispatch( setWorkers( _workers ) );    
}

const LoadCol = (index , startDate) => {  
    let items = [];
    const hRow = heightRowCalendar + "px";
    for(let i = startHour ; i < endHour ; i++ )  {
            items.push(            
            <div key={i}  >    
            <div id={`hour_${i}`} style={{height: `${hRow}` , width: "10%", marginTop: "-11px" , float: "left"}} className='hourText'>{i}</div>    
            <div id={`cal_${index}_${i}`} style={{borderTop: "1px solid black" , height: `${hRow}` , width: "90%" , color: "rgb(183 181 181)" , float: "left"}} onClick={()=>handleAddVisit(i , props.work , startDate)}></div> 
            { _visits  && <VisitBox key={i+'_'+props.index} visits={_visits} hour={i} work={props.work} />}
            <div style={{clear: "both"}}></div>
            </div>
            );
    }
    return(items);
}
let nameW = '';
const iProp = parseInt(settings.visible_name_worker)
if(iProp === 1) nameW = props.work.namesurname;
if(iProp === 2) nameW = props.work.short_name;
let startDate = new Date(currentDateTime);
startDate.setHours(0 , 0 , 0 , 0);

return (
<div key={props.work.id} className="col-md noGutter" style={{textAlign:"center" }}>
    <div >
        <div className='mainBoxWorkerName'>  
            <div style={{border: '2px black solid' , backgroundColor: `${props.work.color}C8` , padding: "10px 0px 10px 0px" , cursor: 'pointer' }} onClick={()=>handleShowCalendarWorker(props.work , props.index)}>
                <div > {nameW}</div>
            </div>
        </div>
    </div>
    
    <div style={{marginTop: "30px"}}> 
    {  LoadCol(props.index , startDate)  }
    <div style={{clear: "both"}}></div>
    </div>
</div>
)
    
}

const VisitApp = (props) => {
const workers = useSelector(store => store.workers);
const kindCalendar = useSelector(store => store.kindCalendar);
//wykrywanie sesji wyłączono. Przenieeskono sprawdzanie sessi podczas kliknięcia w umawianie wizyty
//const [sess , setSess] = useState(false);

// const isSess=()=> {
//     return new Promise( (res ) => {
//         isSession().then( resolve => {
//             if(typeof resolve === 'object' && (!Array.isArray(resolve)) && (resolve !== null)  ) {
//                 if(resolve.session.status) {
//                     if(resolve.session.status === 'notLogged') res(false);                
//                     if(resolve.session.status === 'logged') res(true);            
//                 }
//                 else res (false);
//             } else res(false);
//         })
//         .catch((e)=>{
//             console.log('Błąd aplikacji: ' + e);
//             alert('Błąd aplikacji: ' + e);
//             document.location.href = webAddress;
//           });

//     });
 
// }

// const isSesss=()=>{
//     isSess().then(resp => {
//         if(resp)setSess(true);
//         else {
//             document.location.href = webAddress;
//             setSess(true);
//         }
//     }) ;
// }

const showWorkers=()=> {
let _workers = workers.map( (work , index) => {
    if( !parseInt( work.active ) || !parseInt( work.visible )) return false;
    return(
    <WorkerApp key={work.id} work={work} index={index} visits={props.visits} startTime={props.startTime} />  
    ) 
});
 return _workers
}



const isCondition = (kind)=> (((kindCalendar.kind & kind)===kind ) && (kindCalendar.calData != null)) ? true : false;
 

useEffect( ()=> {
    } , []  );

return (
    <>
    
    <div className="row noGutter">
        {workers && showWorkers()}
    </div>

    { /*(isCondition(NEW) && sess  )  && <VisitWindow type='new' />  */} {/*if kindCalendar is 01010 than true */}
    { isCondition(NEW)   && <VisitWindow type='new' />  } {/*if kindCalendar is 01010 than true */}

    { isCondition(EDIT)  && <VisitWindow type='edit' />  } {/*if kindCalendar is 10010 than true */}

    </>    
    )
}

export default VisitApp;