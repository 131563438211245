import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

import "./AddNewConsumer.css";
export const REMOVE_VISIT = 'REMOVE_VISIT';
export const REMOVE_WORKER = 'REMOVE_WORKER';

export const NORMAL = 'NORMAL';
export const WITH_TEXTAREA = 'WITH_TEXTAREA';

//czy menu ma statyczne? czy pływające
const staticMenu = true;

const DialogYesAndNo = (props) => {
const [limit , setLimit] = useState(0);

let pos = {x: 0 , y: 0};  
let mMove = false;    

const handleMouseDown=(e)=>
{
    if(!staticMenu) {
        let mainBar = document.getElementById('dialogYesAndNoMainBar');
        mainBar.style.position = 'absolute';
        let xFar = e.pageX - mainBar.offsetLeft ;
        let yFar = e.pageY - mainBar.offsetTop ;
        pos = { 
            x: xFar,
            y: yFar
        }
        mMove = true;
    }
}  

const handleMouseUp=(e)=>
{
    mMove = false;
} 

const handlerMouseMove=(ex)=>
{
    if(mMove) {
        let mainBar = document.getElementById('dialogYesAndNoMainBar');
        mainBar.style.left = `${ex.pageX-pos.x}px`; 
        mainBar.style.top = `${ex.pageY-pos.y}px`;
        }
}

window.addEventListener('mouseup' , ()=>{ 
mMove = false;
})  


const handleChangeTextArea=(e)=>{
    setLimit( e.target.value.length );
}

//useEffect
useEffect(()=>{
setLimit(textareaContent.length)    
window.scrollTo({
    top: 0,
    left: 0 , 
    behavior: 'smooth'
})   
} , []); 

const typeDialogBox = props.typeDialogBox ? props.typeDialogBox : NORMAL;

let titleBar = props.titleBar;
let question = props.question;

const textareaContent = props.textareaContent ? props.textareaContent : '';

const textareaBox = ()=> {

    return (
        <>
        <div>
            <div className='textareaMessageBox'>
                <textarea id='visitDescription' className="form-control" aria-label="With textarea" defaultValue={textareaContent} onChange={handleChangeTextArea}></textarea>
            </div>
            <div className='textareaMessageCounter'>
                <span className={limit>160 ? 'redText' : ''}>{limit}/160</span>
            </div>
        </div>
        </>
    )
}

return ( 
     <> 
            <div className='bgOpenMenu' style={{zIndex: 1000}}></div>
             <div className="openMenu" style={{zIndex: 1001}}>
                <div className="row-1">
                    
                        <div id="dialogYesAndNoMainBar" className="mainBarDialogBox" onMouseMove={handlerMouseMove}>
                            <div id="dialogYesAndNoTopBar" className="topBar" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                                <p style={{textAlign: "left"}}>{titleBar}</p>
                            </div>
                            <div className='dialogBoxCentralBar'>
                                <div className="centralBarText" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }}>

                                </div>
                                <div>
                                    { typeDialogBox === WITH_TEXTAREA && textareaBox() }
                                </div>
                            </div>
                            <div className="bottomBar">
                                <div className="buttons_main">
                                <button type="button" className="btn btn-dark btn-sm button-make" onClick={()=>props.handleButtonYes(props.idVisit)}>Tak</button>             
                                <button type="button" className="btn btn-dark btn-sm button-close" onClick={()=>props.handleButtonNo()}>Nie</button>
                                </div>
                            </div>
                            
                        </div>
                    
                    
                </div>
            </div>
     </>   
     );
}
 
export default DialogYesAndNo;